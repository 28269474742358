import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Container, Row, Col, Alert } from 'react-bootstrap';

function App() {
  return (
    <>
      <Container>
        <Row>
          <Col md="12" className="text-center">
            <div id="header-app-brand-logo" data-testid="logo"></div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col sm={{ span: 10, offset: 1}} md={{ span: 10, offset: 1}} lg={{ span: 8, offset: 2}}>
            <Alert variant="danger" className="text-center">
              <h1>This Application is Decommissioned</h1>
              <p>We apologize for any inconvenience.</p>
            </Alert>
          </Col>
        </Row>
      </Container>

      <div className="footer">
        <Container>
          <Row>
            <Col xs={{ offset: 1, span: 10 }} sm={{ offset: 2, span: 8 }} md={{ offset: 3, span: 6 }}
              className="text-center footer-info-legal">
              <p>
                <a href="https://www.firstdata.com/en_us/privacy.html" target="_blank" rel="noreferrer">Privacy Statement</a>
                . © 2025 Fiserv, Inc. or its affiliates.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default App;
